// 上传
const uploadUrl = `/gateway/blade-resource/oss/endpoint/put-file`;
//获取区级联动接口
const areaLinkUrl = "/gateway/hc-space/space/getSpaceTreeList";
// 获取所有的脸用户数据
const getAllFaceUserInfo = `/gateway/hc-mini/user/aio/getAllFaceUserInfo`;

// 查询 - 设备信息
const getYtjDeviceInfoUrl = `/gateway/hc-mini/user/aio/getDeviceInfo`;

export {
  areaLinkUrl,
  uploadUrl,
  getAllFaceUserInfo,
  getYtjDeviceInfoUrl
};
